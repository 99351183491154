import React, { useEffect } from 'react';

const AdSenseAd = ({ adSlotId, adTest = false }) => {
  useEffect(() => {
    const adElements = document.querySelectorAll('.adsbygoogle');

    adElements.forEach((ad) => {
      // Clear the ad slot before pushing a new ad to avoid duplication errors
      ad.innerHTML = '';
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Error loading AdSense ads", e);
      }
    });

    // Push a new ad
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Error loading AdSense ads", e);
    }
  }, [adSlotId]);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: 'display:block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-1007884522567805"
        data-ad-slot="8045914234"
        /* data-adtest={adTest ? 'on' : 'off'} */ß
        data-adtest="on">
      </ins>
    </div>
  );
};

export default AdSenseAd;
