import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function TweedePinksterdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="tweede-pinksterdag-info">
        <h2>Wat is 2de Pinksterdag?</h2>
        <p>
          2de Pinksterdag, of Tweede Pinksterdag, wordt gevierd op de maandag na Pinksteren en is een officiële feestdag in Nederland. 
          Het is een verlenging van de Pinksterfeesten en biedt een extra vrije dag voor rust, reflectie en verschillende activiteiten.
        </p>
        <AdSenseAd />
        <h3>Religieuze Achtergrond</h3>
        <p>
          Pinksteren zelf is een belangrijke christelijke feestdag die de neerdaling van de Heilige Geest op de apostelen herdenkt, 
          zeven weken na Pasen. 2de Pinksterdag heeft minder religieuze rituelen, maar wordt nog steeds gezien als een deel van de Pinksterviering.
        </p>
        <h3>Familie en Vrije Tijd</h3>
        <p>
          Voor veel Nederlanders is 2de Pinksterdag een gelegenheid om tijd door te brengen met familie en vrienden. 
          Het is een dag voor uitjes, familiebezoeken, en ontspanning. Veel mensen maken gebruik van het lange weekend om eropuit te gaan, te wandelen of te fietsen in de natuur.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Op 2de Pinksterdag worden er door het hele land verschillende evenementen en activiteiten georganiseerd. 
          Dit varieert van braderieën en markten tot concerten en sportevenementen. Het is een dag waarop gemeenschappen samenkomen om te genieten van de festiviteiten.
        </p>
        <h3>Kastelen en Musea</h3>
        <p>
          Veel kastelen en musea openen hun deuren op 2de Pinksterdag en organiseren speciale evenementen. 
          Dit biedt een geweldige kans om meer te leren over de Nederlandse geschiedenis en cultuur, terwijl je geniet van een dagje uit.
        </p>
        <h3>Natuur en Recreatie</h3>
        <p>
          De natuur speelt een grote rol op 2de Pinksterdag. Nationale parken en natuurgebieden zijn populaire bestemmingen voor gezinnen en natuurliefhebbers. 
          Veel mensen kiezen ervoor om te gaan wandelen, fietsen of picknicken, gebruik makend van het vaak mooie weer in deze tijd van het jaar.
        </p>
        <h3>Lokale Tradities</h3>
        <p>
          In sommige regio's van Nederland zijn er unieke lokale tradities die plaatsvinden op 2de Pinksterdag. Dit kan variëren van traditionele spelen en wedstrijden tot specifieke culturele vieringen die de gemeenschap samenbrengen.
        </p>
        <p>
          Al met al is 2de Pinksterdag een dag van ontspanning en genieten. Of je nu kiest voor een actief uitje in de natuur, een cultureel bezoek aan een kasteel of museum, 
          of gewoon een rustige dag doorbrengt met familie en vrienden, 2de Pinksterdag biedt voor ieder wat wils.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default TweedePinksterdag;
