import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function Oudjaarsdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="oudjaarsdag-info">
        <h2>Wat is Oudjaarsdag?</h2>
        <p>
          Oudjaarsdag, of Oudejaarsavond zoals het vaak wordt genoemd, is een van de meest feestelijke avonden van het jaar in Nederland. 
          Het is een tijd om het oude jaar af te sluiten en het nieuwe jaar te verwelkomen met vrienden, familie, vuurwerk en natuurlijk oliebollen.
        </p>
        <AdSenseAd />
        <h3>De Voorbereidingen</h3>
        <p>
          De dagen voorafgaand aan Oudjaarsdag zijn vaak gevuld met voorbereidingen. Mensen maken hun huis schoon, halen boodschappen en bereiden feestelijke maaltijden voor. 
          Een belangrijk onderdeel van deze voorbereidingen is het kopen van vuurwerk. Hoewel het afsteken van vuurwerk officieel pas om middernacht mag, 
          kijken veel mensen al uit naar de spectaculaire shows die ze zelf kunnen creëren.
        </p>
        <h3>Oliebollen en Appelbeignets</h3>
        <p>
          Geen Oudjaarsdag zonder oliebollen en appelbeignets! Deze heerlijke lekkernijen worden traditioneel gegeten op Oudjaarsavond en zijn onlosmakelijk verbonden met de viering. 
          Oliebollen, vaak bestrooid met poedersuiker, en appelbeignets, met hun knapperige buitenkant en zachte appelvulling, zijn favorieten op menig feesttafel. 
          Veel mensen maken ze zelf, terwijl anderen ze kopen bij een van de vele oliebollenkramen die in december verschijnen.
        </p>
        <h3>Gezelligheid en Spelletjes</h3>
        <p>
          Oudjaarsavond wordt vaak doorgebracht in het gezelschap van vrienden en familie. Het is een tijd voor gezelligheid, spelletjes en terugblikken op het afgelopen jaar. 
          Populaire activiteiten zijn het kijken naar oudejaarsconferences, waarin cabaretiers op humoristische wijze het jaar doornemen, en het spelen van bordspellen. 
          Veel mensen maken er een gewoonte van om samen te komen, te eten en te drinken, en herinneringen op te halen.
        </p>
        <h3>Vuurwerk en de Jaarwisseling</h3>
        <p>
          Het hoogtepunt van de avond is natuurlijk de jaarwisseling zelf. Om middernacht verzamelen mensen zich buiten om het nieuwe jaar in te luiden met vuurwerk. 
          De lucht vult zich met kleuren en geluiden terwijl het vuurwerk wordt afgestoken. Het is een moment van vreugde en opwinding, waarbij mensen elkaar het beste wensen voor het komende jaar.
        </p>
        <p>
          Voor wie liever niet zelf vuurwerk afsteekt, zijn er professionele vuurwerkshows die in veel steden worden georganiseerd. Deze shows trekken vaak grote menigten en bieden een spectaculaire manier om het nieuwe jaar te beginnen.
        </p>
        <h3>Goede Voornemens</h3>
        <p>
          Een ander traditioneel aspect van Oudjaarsdag is het maken van goede voornemens. Veel mensen nemen deze tijd om na te denken over wat ze in het nieuwe jaar willen bereiken of veranderen. 
          Of het nu gaat om gezonder leven, meer tijd doorbrengen met familie of een nieuwe hobby oppakken, goede voornemens zijn een manier om het nieuwe jaar met frisse energie en motivatie te beginnen.
        </p>
        <h3>Veiligheid en Gemeenschapszin</h3>
        <p>
          Hoewel het afsteken van vuurwerk veel vreugde brengt, is veiligheid een belangrijk aandachtspunt. De overheid en verschillende organisaties benadrukken het belang van veilig omgaan met vuurwerk en het respecteren van de regels. 
          Steeds meer gemeentes organiseren daarom centrale vuurwerkshows, zodat mensen veilig kunnen genieten van het spektakel.
        </p>
        <p>
          Oudjaarsdag is ook een tijd van gemeenschapszin. Buurten organiseren vaak samenkomsten, en er is een gevoel van saamhorigheid en verbondenheid. 
          Het is een kans om oude vrienden te ontmoeten en nieuwe contacten te leggen, terwijl iedereen samenkomt om het nieuwe jaar te vieren.
        </p>
        <h3>Een Nacht om Nooit te Vergeten</h3>
        <p>
          Oudjaarsdag in Nederland is een avond vol traditie, gezelligheid en vreugde. Of je nu geniet van een rustige avond thuis met familie, deelneemt aan een groots feest, of buiten staat omringd door vuurwerk, deze nacht biedt voor ieder wat wils. 
          Het is een tijd om het verleden te waarderen en met hoop en enthousiasme naar de toekomst te kijken. Dus bereid je voor, trek je feestelijke outfit aan en geniet van alles wat Oudjaarsdag te bieden heeft!
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default Oudjaarsdag;
