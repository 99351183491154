import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function Koningsdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="koningsdag-info">
        <h2>Wat is Koningsdag?</h2>
        <p>
          Koningsdag, gevierd op 27 april, is een nationale feestdag in Nederland ter ere van de verjaardag van de koning, momenteel Koning Willem-Alexander. 
          Deze dag is een van de meest populaire en kleurrijke festiviteiten in het land, waarbij mensen massaal de straat op gaan om deel te nemen aan allerlei activiteiten en evenementen.
        </p>
        <AdSenseAd />
        <h3>De Vooravond: Koningsnacht</h3>
        <p>
          De viering begint vaak al de avond ervoor, bekend als Koningsnacht, met muziekfestivals en feesten in veel steden. 
          Op Koningsdag zelf worden er vrijmarkten gehouden, waar mensen zonder vergunning hun tweedehands spullen kunnen verkopen. 
          Dit geeft de dag een bijzondere sfeer van saamhorigheid en ondernemerschap, waarbij jong en oud hun koopwaar uitstallen op kleedjes in parken en op pleinen.
        </p>
        <h3>Oranjegekte</h3>
        <p>
          Een ander kenmerk van Koningsdag is de overvloed aan oranje, de nationale kleur van Nederland en een verwijzing naar het Huis van Oranje-Nassau, het koninklijk huis. 
          Mensen kleden zich in oranje kleding en accessoires, en er zijn vaak oranje vlaggen, slingers en andere decoraties te zien.
        </p>
        <h3>Grote Evenementen</h3>
        <p>
          In steden zoals Amsterdam, Rotterdam en Utrecht worden grote evenementen georganiseerd, waaronder concerten, dansfeesten en sportieve activiteiten. 
          Ook is er traditioneel een officiële koninklijke route, waarbij de koning en zijn familie een stad of dorp bezoeken om deel te nemen aan de festiviteiten en kennis te maken met de lokale bevolking.
        </p>
        <h3>Optredens en Straatmuzikanten</h3>
        <p>
          Koningsdag staat ook bekend om de vele optredens van artiesten, straatmuzikanten en andere performers, die de straten vullen met muziek en entertainment. 
          Dit alles zorgt voor een levendige en feestelijke atmosfeer, waarbij gemeenschapszin en nationale trots centraal staan.
        </p>
        <p>
          Al met al is Koningsdag een dag van feest, traditie en nationale eenheid, waarbij iedereen de kans krijgt om op een unieke manier de verjaardag van de koning te vieren.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default Koningsdag;
