import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';


function Bouwvak({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
      <h2>Wat is de Bouwvak?</h2>
      <p>
        De Bouwvak is een traditionele zomervakantieperiode voor de bouwsector in Nederland. Tijdens deze periode sluiten veel bouwbedrijven hun deuren, 
        waardoor werknemers een paar weken vrij krijgen om te ontspannen en op vakantie te gaan. De Bouwvak is een belangrijke periode voor de sector en wordt jaarlijks gepland.
      </p>
      <div className="important-notes">
        <h2>Belangrijke Opmerkingen</h2>
        <ul>
          <li><strong>Bouwvak, Voorjaarsvakantie, Herfstvakantie:</strong> Dit zijn adviesdata. Scholen kunnen hiervan afwijken.</li>
          <li><strong>Voor alle vakanties geldt:</strong> Controleer altijd de specifieke data op de website van de school, aangezien scholen soms mogen afwijken van de landelijke adviesdata.</li>
        </ul>
      </div>
      <div className="bouwvak-info">
        <AdSenseAd />
        <h3>Geschiedenis van de Bouwvak</h3>
        <p>
          De Bouwvak werd voor het eerst ingesteld in de jaren 60 om werknemers in de bouwsector een gegarandeerde vakantieperiode te bieden. 
          Het was bedoeld om de gezondheid en het welzijn van werknemers te bevorderen door hen een pauze van het zware fysieke werk te geven. 
          Sindsdien is het een vast onderdeel van de jaarlijkse planning in de bouwsector geworden.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/bouwvak_beach.jpg`} alt="Bouwvak Beach" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px', display: 'block', margin: '0 auto' }} />
        <h3>Periodes en Regio's</h3>
        <p>
          De Bouwvak is opgedeeld in drie regio's: Noord, Midden en Zuid Nederland. Dit helpt om de drukte op de wegen en vakantiebestemmingen te spreiden. 
          De exacte data variëren elk jaar, maar de Bouwvak vindt meestal plaats in juli en augustus. De vakantieperiodes worden ruim van tevoren bekendgemaakt, zodat bedrijven en werknemers hun plannen kunnen maken.
        </p>
        <h3>Vakantie en Ontspanning</h3>
        <p>
          Voor veel bouwvakkers en hun gezinnen is de Bouwvak een tijd om op vakantie te gaan. Populaire bestemmingen zijn zowel binnen Nederland, zoals de kust, de Waddeneilanden en natuurgebieden, als bestemmingen in het buitenland zoals Frankrijk, Spanje en Italië. 
          De Bouwvak biedt een kans om te ontspannen, nieuwe plekken te ontdekken en quality time door te brengen met familie en vrienden.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/bouwvak_history.jpg`} alt="Bouwvak History" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px', display: 'block', margin: '0 auto' }} />
        <h3>Invloed op de Bouwsector</h3>
        <p>
          Tijdens de Bouwvak ligt het werk in de bouwsector grotendeels stil. Dit heeft invloed op de planning en uitvoering van bouwprojecten, die rondom deze periode worden georganiseerd. 
          Bouwbedrijven houden rekening met de Bouwvak bij het opstellen van hun projectplanningen om ervoor te zorgen dat deadlines worden gehaald en projecten op schema blijven.
        </p>
        <h3>Alternatieve Vakantieperiodes</h3>
        <p>
          Hoewel de Bouwvak traditioneel de belangrijkste vakantieperiode voor de bouwsector is, zijn er steeds meer bedrijven die flexibele vakantieperiodes aanbieden. 
          Dit stelt werknemers in staat om buiten de drukke zomermaanden vakantie op te nemen, wat kan bijdragen aan een betere spreiding van werk en vrije tijd.
        </p>
        <p>
          Al met al is de Bouwvak een gevestigde traditie in Nederland, die bouwvakkers de kans biedt om te ontspannen en op te laden. 
          Of je nu kiest voor een actieve vakantie, een ontspannen verblijf in eigen land, of een verre reis, de Bouwvak biedt de perfecte gelegenheid om even afstand te nemen van het werk en te genieten van de zomer.
        </p>
      </div>
      <h3>Alle bouwvak data op een rij:</h3>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
      <AdSenseAd />
    </div>
  );
}

export default Bouwvak;
