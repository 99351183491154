import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function TweedeKerstdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="tweede-kerstdag-info">
        <h2>Wat is Tweede Kerstdag?</h2>
        <p>
          Tweede Kerstdag, gevierd op 26 december, is een officiële feestdag in Nederland die volgt op Eerste Kerstdag. 
          Het is een extra dag om de kerstvieringen voort te zetten en te genieten van tijd met familie en vrienden.
        </p>
        <AdSenseAd />
        <h3>Geschiedenis en Oorsprong</h3>
        <p>
          Tweede Kerstdag, ook bekend als Sint-Stefanusdag, heeft zijn oorsprong in de christelijke traditie. 
          Het is vernoemd naar Sint Stefanus, de eerste christelijke martelaar. In veel Europese landen is het gebruikelijk om 
          zowel Eerste als Tweede Kerstdag te vieren, wat extra tijd biedt voor religieuze vieringen en familieactiviteiten.
        </p>
        <h3>Familie en Vrienden</h3>
        <p>
          Net als Eerste Kerstdag, wordt Tweede Kerstdag vaak gevierd met familie en vrienden. Veel mensen gebruiken deze dag om verdere 
          familiebijeenkomsten te houden, uitgebreid te lunchen of dineren en te genieten van elkaars gezelschap. Het is ook een populaire dag voor 
          mensen om bij elkaar op bezoek te gaan, vooral als ze dat op Eerste Kerstdag niet hebben kunnen doen.
        </p>
        <h3>Winkelen en Uitjes</h3>
        <p>
          In tegenstelling tot Eerste Kerstdag, wanneer de meeste winkels gesloten zijn, openen veel winkels op Tweede Kerstdag hun deuren. 
          Dit maakt het een populaire dag voor winkelen en het gebruikmaken van kerstuitverkoop. Daarnaast zijn veel mensen te vinden in natuurgebieden, 
          wandelend of fietsend, of bezoekend aan musea en andere attracties die vaak speciale kerstprogramma's hebben.
        </p>
        <h3>Rust en Ontspanning</h3>
        <p>
          Voor veel mensen is Tweede Kerstdag een dag van rust en ontspanning na de drukte van Eerste Kerstdag. Het biedt een kans om te genieten van een rustige dag thuis, 
          te ontspannen met een goed boek of een kerstfilm, en om zich mentaal voor te bereiden op de terugkeer naar het normale leven na de feestdagen.
        </p>
        <p>
          Al met al biedt Tweede Kerstdag een waardevolle aanvulling op de kerstvieringen. Of je nu kiest voor een actieve dag met familie en vrienden, 
          een dagje uit naar een museum of natuurgebied, of gewoon een rustige dag thuis, Tweede Kerstdag biedt een perfecte afsluiting van de kerstperiode.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default TweedeKerstdag;
