import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';

function Zomervakantie({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
<h2>Wat is de Zomervakantie?</h2>
        <p>
          De Zomervakantie is de langste schoolvakantie in Nederland en vindt plaats in de zomermaanden juli en augustus. 
          Deze vakantie biedt leerlingen en leraren een welverdiende pauze van zes weken om te genieten van de zomer.
        </p>
      <div className="important-notes">
        <h2>Belangrijke Opmerkingen</h2>
        <ul>
          <li><strong>Zomervakantie (regio Nijmegen):</strong> Start nooit na de 3e week van juli vanwege de Nijmeegse Vierdaagse. Scholen in deze regio mogen afwijken van de landelijke zomervakantie-data.</li>
          <li><strong>Voor alle vakanties geldt:</strong> Controleer altijd de specifieke data op de website van de school, aangezien scholen soms mogen afwijken van de landelijke adviesdata.</li>
        </ul>
      </div>
      <div className="zomervakantie-info">
        <AdSenseAd />
        <h3>Schoolvakantie voor Basisonderwijs en Voortgezet Onderwijs</h3>
        <p>
          De Zomervakantie geldt voor zowel het basisonderwijs als het voortgezet onderwijs. De exacte data van de Zomervakantie verschillen per regio (Noord, Midden, en Zuid Nederland) om de drukte op vakantiebestemmingen en de wegen te spreiden.
        </p>
        <h3>Vakantie in Nederland en het Buitenland</h3>
        <p>
          Veel gezinnen gebruiken de Zomervakantie om op reis te gaan. Populaire bestemmingen zijn zowel binnen Nederland, zoals de Waddeneilanden, Zeeland en de Veluwe, als bestemmingen in het buitenland zoals Frankrijk, Spanje en Italië. 
          Het is een tijd om te ontspannen, nieuwe plaatsen te ontdekken en quality time door te brengen met familie en vrienden.
        </p>
        <h3>Activiteiten tijdens de Zomervakantie</h3>
        <p>
          Naast reizen, zijn er tal van activiteiten die je in de Zomervakantie kunt ondernemen. Denk aan dagtochten naar pretparken, dierentuinen, musea en waterparken. 
          Veel steden en dorpen organiseren zomerevenementen zoals festivals, markten en sportwedstrijden die voor extra vermaak zorgen.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/zomervakantie-eiland.jpg`} alt="zomervakantie eiland" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        <h3>Sport en Spel</h3>
        <p>
          De Zomervakantie is ook een geweldige tijd voor sport en spel. Kinderen en tieners kunnen deelnemen aan zomerkampen, sportclinics en andere recreatieve activiteiten. 
          Dit biedt niet alleen plezier, maar helpt ook bij het ontwikkelen van nieuwe vaardigheden en het maken van nieuwe vrienden.
        </p>
        <h3>Ontspanning en Vrije Tijd</h3>
        <p>
          De lange zomerdagen bieden volop gelegenheid voor ontspanning en vrije tijd. Of je nu kiest voor een dagje strand, een barbecue in de achtertuin, of gewoon lekker luieren met een goed boek, de Zomervakantie is de perfecte tijd om tot rust te komen.
        </p>
        <h3>Voorbereiding op het Nieuwe Schooljaar</h3>
        <p>
          Aan het einde van de Zomervakantie beginnen veel gezinnen zich voor te bereiden op het nieuwe schooljaar. Dit betekent vaak het kopen van schoolspullen, het opnieuw instellen van routines en het mentaal voorbereiden op de start van een nieuw leerjaar.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/zomervakantie-family.jpg`} alt="zomervakantie familie" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        <p>
          Al met al biedt de Zomervakantie een uitgebreide onderbreking van het schooljaar, een kans om te reizen en te ontspannen, en een gelegenheid om nieuwe ervaringen op te doen. 
          Of je nu kiest voor verre reizen, lokale avonturen of rustige dagen thuis, de Zomervakantie biedt voor ieder wat wils.
        </p>
      </div>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
    </div>
  );
}

export default Zomervakantie;
