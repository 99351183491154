import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';

function SchoolHolidays({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
      <h1>Schoolvakanties in Nederland</h1>
      <p>Blijf op de hoogte van de schoolvakanties in Nederland. Van de voorjaarsvakantie tot de zomervakantie, zorg ervoor dat u de data kent en uw vakanties kunt plannen.</p>
      <p>Bekijk de schoolvakanties per regio: Noord, Midden, en Zuid Nederland. Inclusief vakanties voor basisonderwijs en voortgezet onderwijs.</p>
      <p>Meer weten over <a href="https://feestdagenplanner.nl" target="_blank" rel="noopener noreferrer">feestdagen in Nederland</a>? Bekijk onze pagina over feestdagen.</p>
      <AdSenseAd></AdSenseAd>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
      <img
          src={`${process.env.PUBLIC_URL}/images/Schoolvakantie_page.jpg`}
          alt="Schoolvakanties"
          style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px', display: 'block', margin: '0 auto' }}
        />
      <div className="important-notes">
        <h2>Belangrijke Opmerkingen</h2>
        <ul>
          <li><strong>Meivakantie:</strong> Veel basisscholen zijn een extra week voor of na de officiële meivakantie gesloten. Raadpleeg de website van de school voor de exacte data.</li>
          <li><strong>Bouwvak, Voorjaarsvakantie, Herfstvakantie:</strong> Dit zijn adviesdata. Scholen kunnen hiervan afwijken.</li>
          <li><strong>Zomervakantie (regio Nijmegen):</strong> Start nooit na de 3e week van juli vanwege de Nijmeegse Vierdaagse. Scholen in deze regio mogen afwijken van de landelijke zomervakantie-data.</li>
          <li><strong>Kerstvakantie:</strong> Scholen zijn verplicht gesloten.</li>
          <li><strong>Voor alle vakanties geldt:</strong> Controleer altijd de specifieke data op de website van de school, aangezien scholen soms mogen afwijken van de landelijke adviesdata.</li>
        </ul>
      </div>
    </div>
  );
}

export default SchoolHolidays;
