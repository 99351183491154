import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function Hemelvaart({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="hemelvaart-info">
        <h2>Wat is Hemelvaartsdag?</h2>
        <p>
          Hemelvaartsdag, ook wel bekend als de dag van de Hemelvaart van Jezus, wordt gevierd op de 40ste dag van Pasen en valt altijd op een donderdag. 
          Het herdenkt de opstanding van Jezus Christus naar de hemel, zoals beschreven in het Nieuwe Testament van de Bijbel.
        </p>
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Hemelvaartsdag heeft een diepe religieuze betekenis voor christenen over de hele wereld. Volgens de Bijbel verscheen Jezus na zijn opstanding uit de dood 
          veertig dagen lang aan zijn discipelen, voordat hij opsteeg naar de hemel om aan de rechterhand van God te zitten. Dit moment wordt gezien als een 
          belangrijk onderdeel van het christelijke geloof, omdat het de overwinning van Jezus over de dood en zijn verheffing tot een plaats naast God markeert.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          In Nederland wordt Hemelvaartsdag gevierd met kerkdiensten en diverse religieuze evenementen. Veel mensen gaan naar de kerk om deze dag te markeren 
          en deel te nemen aan speciale vieringen. Het is ook gebruikelijk om op Hemelvaartsdag te genieten van de natuur, en veel mensen maken een wandeling of fietstocht.
        </p>
        <h3>Vrije Dag en Activiteiten</h3>
        <p>
          Hemelvaartsdag is een officiële feestdag in Nederland, wat betekent dat de meeste mensen vrij hebben van werk en scholen gesloten zijn. Dit geeft mensen 
          de gelegenheid om tijd door te brengen met familie en vrienden, en om te genieten van verschillende activiteiten en evenementen die op deze dag worden georganiseerd.
        </p>
        <h3>Hemelvaartsdag en Pinksteren</h3>
        <p>
          Hemelvaartsdag valt altijd 39 dagen na Pasen en 10 dagen voor Pinksteren, de dag waarop de komst van de Heilige Geest wordt gevierd. Deze periode tussen 
          Hemelvaart en Pinksteren wordt vaak gezien als een tijd van bezinning en voorbereiding op het Pinksterfeest, dat de geboorte van de christelijke kerk markeert.
        </p>
        <h3>Natuur en Recreatie</h3>
        <p>
          Veel Nederlanders maken van de gelegenheid gebruik om op Hemelvaartsdag de natuur in te gaan. Wandelen, fietsen en picknicken zijn populaire activiteiten op deze dag. 
          Nationale parken en natuurgebieden zijn vaak druk bezocht, en veel mensen genieten van de schoonheid van de lente.
        </p>
        <h3>Hemelvaartsdag in de Gemeenschap</h3>
        <p>
          Naast individuele en familieactiviteiten, worden er in veel gemeenschappen ook evenementen georganiseerd. Dit kan variëren van markten en braderieën tot muziekoptredens 
          en sportevenementen. Deze lokale festiviteiten dragen bij aan de gemeenschapszin en bieden een leuke manier om de dag door te brengen.
        </p>
        <p>
          Al met al is Hemelvaartsdag een dag van religieuze betekenis, ontspanning en gemeenschap. Of je nu de dag doorbrengt met gebed, een wandeling in de natuur, 
          of het bijwonen van een lokaal evenement, Hemelvaartsdag biedt een waardevolle gelegenheid om even stil te staan en te genieten van de tijd met geliefden.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default Hemelvaart;
