import React, { } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroBanner from './components/HeroBanner';

import SchoolHolidays from './components/SchoolHolidays/SchoolHolidays';
import Herfstvakantie from './components/SchoolHolidays/Herfstvakantie';
import Kerstvakantie from './components/SchoolHolidays/Kerstvakantie';
import Voorjaarsvakantie from './components/SchoolHolidays/Voorjaarsvakantie';
import Meivakantie from './components/SchoolHolidays/Meivakantie';
import Zomervakantie from './components/SchoolHolidays/Zomervakantie';
import Bouwvak from './components/SchoolHolidays/Bouwvak';
import Regio from './components/SchoolHolidays/Regio'

import Holidays from './components/Holidays/Holidays';
import Bevrijdingsdag from './components/Holidays/Bevrijdingsdag';
import Goedevrijdag from './components/Holidays/Goedevrijdag';
import Koningsdag from './components/Holidays/Koningsdag';
import Oudjaarsdag from './components/Holidays/Oudjaarsdag';
import Nieuwjaarsdag from './components/Holidays/Nieuwjaarsdag';
import EerstePinksterdag from './components/Holidays/EerstePinksterdag';
import TweedePinksterdag from './components/Holidays/TweedePinksterdag';
import EersteKerstdag from './components/Holidays/EersteKerstdag';
import TweedeKerstdag from './components/Holidays/TweedeKerstdag';
import Hemelvaart from './components/Holidays/Hemelvaart';
import EerstePaasdag from './components/Holidays/EerstePaasdag';
import TweedePaasdag from './components/Holidays/TweedePaasdag';
import Suikerfeest from './components/Holidays/Suikerfeest';

import PaklijstZomervakantie from './components/Paklijsten/PaklijstZomervakantie'
import PaklijstWintersport from './components/Paklijsten/PaklijstWintersport'
import CityTripPaklijst from './components/Paklijsten/CitytripPaklijst'
import WandelvakantiePaklijst from './components/Paklijsten/WandelvakantiePaklijst'
import CruisevakantiePaklijst from './components/Paklijsten/CruisevakantiePaklijst'

import Disclaimer from './components/Disclaimer';
import CookieConsent from './components/CookieConsent';
/* import VakantiePlanner from './components/VakantiePlanner';  */



import './App.css';

function App() {
  const currentYear = new Date().getFullYear().toString();

  //Schoolvakanties
  const schoolvakantieFilter = { vakantie: '', year: currentYear };
  const zomervakantieFilters = { vakantie: 'Zomervakantie', year: '' };
  const voorjaarsvakantieFilters = { vakantie: 'Voorjaarsvakantie', year: '' };
  const meivakantieFilters = { vakantie: 'Meivakantie', year: '' };
  const kerstvakantieFilters = { vakantie: 'Kerstvakantie', year: '' };
  const bouwvakFilters = { vakantie: 'Bouwvak', year: '' };
  const herfstvakantieFilters = { vakantie: 'Herfstvakantie', year: '' };


  //Feestdagen
  const feestdagenFilters = { search: '', year: currentYear };
  const koningsdagFilters = { search: 'Koningsdag', year: '' };
  const nieuwjaarsdagFilter = { search: 'Nieuwjaarsdag', year: '' };
  const oudjaarsdagFilter = { search: 'Oudjaarsdag', year: '' };
  const eerstepinksterdagFilter = { search: '1ste Pinksterdag', year: '' };
  const tweedepinksterdagFilter = { search: '2de Pinksterdag', year: '' };
  const goedevrijdagFilter = { search: 'Goede vrijdag', year: '' };
  const bevrijdingsdagFilter = { search: 'Bevrijdingsdag', year: '' };
  const EerstePaasdagFilter = { search: '1ste Paasdag', year: '' };
  const TweedePaasdagFilter = { search: '2de paasdag', year: '' };
  const EersteKerstdagFilter = { search: '1ste kerstdag', year: '' };
  const TweedeKerstdagFilter = { search: '2de kerstdag', year: '' };
  const HemelvaartFilter = { search: 'Hemelvaart', year: '' };
  const SuikerfeestFilter = { search: 'Suikerfeest', year: '' };

  return (
    <Router>
      <Header />
      <HeroBanner />
      <main className="main-content">
        <div className="content-container">
          <Routes>
            <Route path="/" element={<SchoolHolidays defaultFilters={schoolvakantieFilter} />} />
            <Route path="/herfstvakantie" element={<Herfstvakantie defaultFilters={herfstvakantieFilters} />} />
            <Route path="/bouwvak" element={<Bouwvak defaultFilters={bouwvakFilters} />} />
            <Route path="/kerstvakantie" element={<Kerstvakantie defaultFilters={kerstvakantieFilters} />} />
            <Route path="/voorjaarsvakantie" element={<Voorjaarsvakantie defaultFilters={voorjaarsvakantieFilters} />} />
            <Route path="/meivakantie" element={<Meivakantie defaultFilters={meivakantieFilters} />} />
            <Route path="/zomervakantie" element={<Zomervakantie defaultFilters={zomervakantieFilters} />} />
            <Route path="/schoolvakanties" element={<SchoolHolidays defaultFilters={schoolvakantieFilter} />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/paklijst-zomervakantie" element={<PaklijstZomervakantie />} />
            <Route path="/paklijst-wintersport" element={<PaklijstWintersport />} />
            <Route path="/paklijst-citytrip" element={<CityTripPaklijst />} />    
            <Route path="/paklijst-wandelvakantie" element={<WandelvakantiePaklijst />} />      
            <Route path="/paklijst-cruisevakantie" element={<CruisevakantiePaklijst />} />                      
            <Route path="/regios" element={<Regio />} />
            <Route path="/feestdagen" element={<Holidays defaultFilters={feestdagenFilters} />} />
            <Route path="/koningsdag" element={<Koningsdag defaultFilters={koningsdagFilters} />} />
            <Route path="/nieuwjaarsdag" element={<Nieuwjaarsdag defaultFilters={nieuwjaarsdagFilter} />} />
            <Route path="/oudjaarsdag" element={<Oudjaarsdag defaultFilters={oudjaarsdagFilter} />} />
            <Route path="/1ste pinksterdag" element={<EerstePinksterdag defaultFilters={eerstepinksterdagFilter} />} />
            <Route path="/2de pinksterdag" element={<TweedePinksterdag defaultFilters={tweedepinksterdagFilter} />} />
            <Route path="/bevrijdingsdag" element={<Bevrijdingsdag defaultFilters={bevrijdingsdagFilter} />} />
            <Route path="/goede vrijdag" element={<Goedevrijdag defaultFilters={goedevrijdagFilter} />} />
            <Route path="/1ste paasdag" element={<EerstePaasdag defaultFilters={EerstePaasdagFilter} />} />
            <Route path="/2de paasdag" element={<TweedePaasdag defaultFilters={TweedePaasdagFilter} />} />
            <Route path="/hemelvaart" element={<Hemelvaart defaultFilters={HemelvaartFilter} />} />
            <Route path="/suikerfeest" element={<Suikerfeest defaultFilters={SuikerfeestFilter} />} />
            <Route path="/1ste kerstdag" element={<EersteKerstdag defaultFilters={EersteKerstdagFilter} />} />
            <Route path="/2de kerstdag" element={<TweedeKerstdag defaultFilters={TweedeKerstdagFilter} />} />
            {/* <Route path="/vakantieplanner" element={<VakantiePlanner />} /> */}
          </Routes>
        </div>
      </main>
      <CookieConsent />
      <Footer />
    </Router>
  );
}

export default App;
