import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function EerstePinksterdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">

      <div className="eerste-pinksterdag-info">
        <h2>Wat is 1ste Pinksterdag?</h2>
        <p>
          1ste Pinksterdag, ook wel bekend als Pinksteren, is een christelijke feestdag die zeven weken na Pasen wordt gevierd. 
          Het markeert de neerdaling van de Heilige Geest op de apostelen van Jezus Christus en wordt gezien als het begin van de christelijke kerk.
        </p>
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Pinksteren heeft een diepe religieuze betekenis voor christenen. Het herdenkt het moment waarop de Heilige Geest neerdaalde op de discipelen van Jezus, 
          hen vervulde met spirituele gaven en hen in staat stelde om in verschillende talen te spreken en het evangelie te verspreiden. 
          Dit wordt vaak gezien als het begin van de missie van de kerk in de wereld.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          In Nederland wordt 1ste Pinksterdag gevierd met kerkdiensten en familiebijeenkomsten. Veel mensen bezoeken de kerk om deze heilige dag te vieren 
          en te reflecteren op de betekenis ervan. Na de dienst is het gebruikelijk om samen te komen met familie en vrienden voor een feestelijke maaltijd.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Naast religieuze vieringen zijn er ook veel seculiere evenementen en activiteiten op 1ste Pinksterdag. In veel steden en dorpen worden braderieën, 
          markten en festivals georganiseerd. Dit biedt een kans voor mensen om samen te komen, te genieten van live muziek, kunst en ambachten, en heerlijk eten.
        </p>
        <h3>Pinksterbloemen en Decoraties</h3>
        <p>
          Een mooi gebruik tijdens Pinksteren is het versieren van huizen en kerken met bloemen, vooral pinksterbloemen. Deze kleurrijke decoraties symboliseren 
          nieuw leven en de vreugde van de lente. Veel mensen nemen de tijd om hun huis op te fleuren en een feestelijke sfeer te creëren.
        </p>
        <h3>Natuur en Recreatie</h3>
        <p>
          1ste Pinksterdag valt meestal in de late lente, wanneer het weer vaak mild en aangenaam is. Dit maakt het een ideale dag voor buitenactiviteiten zoals wandelen, 
          fietsen en picknicken. Nationale parken en natuurgebieden zijn populaire bestemmingen voor gezinnen die willen genieten van de natuur en het mooie weer.
        </p>
        <p>
          Al met al is 1ste Pinksterdag een dag van zowel religieuze als culturele betekenis in Nederland. Of je nu deelneemt aan kerkdiensten, geniet van familiebijeenkomsten, 
          of deelneemt aan lokale festivals, deze dag biedt een gelegenheid om samen te komen, te vieren en te genieten van de schoonheid van de lente.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
      </div>
    </div>
  );
}

export default EerstePinksterdag;
