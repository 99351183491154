import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function Holidays({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    } 

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <h1>Feestdagen in Nederland</h1>
      <p>Ontdek alle officiële feestdagen in Nederland. Van Nieuwjaarsdag tot Kerstmis, plan uw vrije dagen en vakanties efficiënt.</p>
      <p>Bekijk de lijst van feestdagen, inclusief religieuze feestdagen zoals Pasen en Ramadan, en nationale feestdagen zoals Koningsdag en Bevrijdingsdag.</p>
      <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <HolidayTable holidays={filteredHolidays} />
      <AdSenseAd />
    </div>
  );
}

export default Holidays;
