import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';  // Import the logo

function Header() {
  return (
    <header className="header">
      <Link to="/" className="logo">
        <img src={logo} alt="Vrije Dagen NL" className="logo-image" /> {/* Add the logo */}
        Vrije Dagen NL
      </Link>
      <nav>
         <div className="dropdown">
          <button className="dropbtn">Feestdagen</button>
          <div className="dropdown-content">
            <Link to="/feestdagen">Alle feestdagen</Link>
            <Link to="/bevrijdingsdag">Bevrijdingsdag</Link>
            <Link to="/koningsdag">Koningsdag</Link>
            <Link to="/goede vrijdag">Goede vrijdag</Link>
            <Link to="/nieuwjaarsdag">Nieuwjaarsdag</Link>
            <Link to="/1ste pinksterdag">1ste pinksterdag</Link>
            <Link to="/2de pinksterdag">2de pinksterdag</Link>
            <Link to="/1ste paasdag">1ste paasdag</Link>
            <Link to="/2de paasdag">2de paasdag</Link>
            <Link to="/oudjaarsdag">Oudjaarsdag</Link>
            <Link to="/hemelvaart">Hemelvaart</Link>
            <Link to="/Suikerfeest">Suikerfeest</Link>
            <Link to="/1ste kerstdag">1ste kerstdag</Link>
            <Link to="/2de kerstdag">2de kerstdag</Link>
          </div>
        </div>       
        <div className="dropdown">
          <button className="dropbtn">Schoolvakanties</button>
          <div className="dropdown-content">
            <Link to="/schoolvakanties">Alle Schoolvakanties</Link>
            <Link to="/bouwvak">Bouwvak</Link>
            <Link to="/herfstvakantie">Herfstvakantie</Link>
            <Link to="/kerstvakantie">Kerstvakantie</Link>
            <Link to="/voorjaarsvakantie">Voorjaarsvakantie</Link>
            <Link to="/meivakantie">Meivakantie</Link>
            <Link to="/zomervakantie">Zomervakantie</Link>
            <Link to="/regios">regio’s</Link>
          </div>
        </div>
        <div className="dropdown">
        <button className="dropbtn">Paklijsten</button>
          <div className="dropdown-content">
            <Link to="/Paklijst-zomervakantie">Paklijst zomervakantie</Link>
            <Link to="/Paklijst-wintersport">Paklijst wintersport</Link>
            <Link to="/Paklijst-citytrip">Paklijst citytrip</Link>  
            <Link to="/Paklijst-wandelvakantie">Paklijst citytrip</Link>     
                   
          </div> 
          </div>
          {/* <Link to="/vakantieplanner">Vakantieplanner</Link> */}
      </nav>
    </header>
  );
}

export default Header;
