import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';

function Meivakantie({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
        <h2>Wat is de Meivakantie?</h2>
        <p>
          De Meivakantie is een schoolvakantie in Nederland die meestal plaatsvindt in de laatste week van april en de eerste week van mei. 
          Deze vakantie valt vaak samen met de nationale feestdagen Koningsdag (27 april) en Bevrijdingsdag (5 mei).
        </p>
      <div className="important-notes">
        <h2>Belangrijke Opmerking</h2>
        <p>Meivakantie: Veel basisscholen zijn een extra week voor of na de officiële meivakantie gesloten. Raadpleeg de website van de school voor de exacte data.</p>
        <li><strong>Voor alle vakanties geldt:</strong> Controleer altijd de specifieke data op de website van de school, aangezien scholen soms mogen afwijken van de landelijke adviesdata.</li>
      </div>
      <div className="meivakantie-info">
        <AdSenseAd />
        <h3>Schoolvakantie voor Basisonderwijs en Voortgezet Onderwijs</h3>
        <p>
          De Meivakantie is bedoeld voor zowel het basisonderwijs als het voortgezet onderwijs. Het biedt leerlingen en leraren een welverdiende pauze 
          en een kans om uit te rusten en tijd door te brengen met familie en vrienden. De exacte data kunnen per regio en per jaar variëren, 
          maar de vakantie beslaat meestal één tot twee weken.
        </p>
        <h3>Activiteiten tijdens de Meivakantie</h3>
        <p>
          Tijdens de Meivakantie worden er door het hele land verschillende activiteiten en evenementen georganiseerd. 
          Veel families gebruiken deze tijd om op vakantie te gaan, dagtochten te maken of culturele activiteiten te ondernemen. 
          Populaire bestemmingen zijn pretparken, dierentuinen, musea en natuurparken.
        </p>
        <h3>Koningsdag en Bevrijdingsdag</h3>
        <p>
          De Meivakantie valt vaak samen met Koningsdag en Bevrijdingsdag, twee belangrijke nationale feestdagen in Nederland. 
          Koningsdag (27 april) wordt gevierd met vrijmarkten, feesten en evenementen in het hele land, terwijl Bevrijdingsdag (5 mei) 
          herinnert aan het einde van de Duitse bezetting tijdens de Tweede Wereldoorlog en wordt gevierd met festivals en herdenkingen.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/meivakantie-koningsdag.jpg`} alt="meivakantie koningsdag" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        <h3>Rust en Ontspanning</h3>
        <p>
          Naast het deelnemen aan georganiseerde activiteiten, is de Meivakantie ook een tijd voor rust en ontspanning. Veel mensen kiezen ervoor om thuis te blijven, 
          te genieten van de lente en quality time door te brengen met hun gezin. Het is een perfecte tijd om te ontspannen, te lezen, te wandelen of gewoon te genieten van de langere dagen en warmere temperaturen.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/meivakantie-familie.jpg`} alt="meivakantie familie" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        <h3>Voorbereiding op de Zomervakantie</h3>
        <p>
          Voor veel gezinnen is de Meivakantie ook een tijd om plannen te maken voor de zomervakantie. Het is een kans om reisbestemmingen te bespreken, 
          boekingen te maken en ervoor te zorgen dat alles geregeld is voor de lange vakantieperiode in de zomer.
        </p>
        <p>
          Al met al biedt de Meivakantie een waardevolle onderbreking in het schooljaar, een kans om belangrijke nationale feestdagen te vieren en 
          een gelegenheid om te genieten van de lente met familie en vrienden. Of je nu kiest voor actieve uitstapjes, culturele evenementen of gewoon rust en ontspanning, 
          de Meivakantie biedt voor ieder wat wils.
        </p>
      </div>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
    </div>
  );
}

export default Meivakantie;
