import React, { useState } from 'react';
import './styles.css';
import AdSenseAd from '../AdSenseAd';

function ZomervakantiePaklijst() {
  const [items, setItems] = useState([
    { name: 'Paspoort/ID', checked: false },
    { name: 'Vliegtickets/Reserveringen', checked: false },
    { name: 'Rijbewijs', checked: false },
    { name: 'Geld/Pinpas/Creditcard', checked: false },
    { name: 'Verzekeringspapieren', checked: false },
    { name: 'Mobiele telefoon + oplader', checked: false },
    { name: 'Kleding', checked: false },
    { name: 'Zwemkleding', checked: false },
    { name: 'Toiletartikelen', checked: false },
    { name: 'Zonnebrandcrème', checked: false },
    { name: 'Medicijnen', checked: false },
    { name: 'Bril/Contactlenzen', checked: false },
    { name: 'Camera + oplader', checked: false },
    { name: 'Boeken/Tijdschriften', checked: false },
    { name: 'Reisgids/Kaarten', checked: false },
    { name: 'Snacks/Drinken', checked: false },
    { name: 'Handdoeken', checked: false },
    { name: 'EHBO-kit', checked: false },
    { name: 'Slaapspullen', checked: false },
    { name: 'Oordopjes', checked: false },
    { name: 'Hoed/Pet', checked: false },
    { name: 'Zonnebril', checked: false },
    { name: 'Paraplu/Regenjas', checked: false },
    { name: 'Sandalen/Slippers', checked: false },
    { name: 'Naaigerei', checked: false },
    { name: 'Nagelschaartje', checked: false },
    { name: 'Zaklamp', checked: false },
    { name: 'Powerbank', checked: false },
    { name: 'Tandenborstel/Tandpasta', checked: false },
    { name: 'Scheerapparaat/Scheermesjes', checked: false },
    { name: 'Deodorant', checked: false },
    { name: 'Shampoo/Conditioner', checked: false },
    { name: 'Haarborstel/Kam', checked: false },
    { name: 'Handgel', checked: false },
    { name: 'Insectenspray', checked: false },
    { name: 'Reiswekker', checked: false },
    { name: 'Reisdocumenten', checked: false },
    { name: 'Portemonnee', checked: false },
    { name: 'Laptop/Tablet + oplader', checked: false },
    { name: 'Flesopener', checked: false },
    { name: 'Plastic zakken', checked: false },
    { name: 'Wasmiddel', checked: false },
    { name: 'Speelgoed (voor kinderen)', checked: false },
    { name: 'Babyspullen (indien nodig)', checked: false },
    { name: 'Sportuitrusting (indien nodig)', checked: false },
    { name: 'Strandlaken', checked: false },
    { name: 'Rugzak/Strandtas', checked: false },
    { name: 'Kussen', checked: false },
    { name: 'Waterfles', checked: false }
  ]);

  const handleCheck = (index) => {
    const newItems = [...items];
    newItems[index].checked = !newItems[index].checked;
    setItems(newItems);
  };
  

  return (
    <div className="container">
      <h1>Paklijst voor Zomervakantie</h1>
      <AdSenseAd />
      <p>Gebruik deze paklijst om ervoor te zorgen dat u niets vergeet voor uw zomervakantie. U kunt de lijst afdrukken en de items afvinken terwijl u inpakt.</p>
      <ul className="paklijst">
        {items.map((item, index) => (
          <li key={index} className="paklijst-item">
            <label>
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() => handleCheck(index)}
              />
              {item.name}
            </label>
          </li>
        ))}
      </ul>
      <button onClick={() => window.print()} className="print-button">Print deze lijst</button>
    </div>
  );
}

export default ZomervakantiePaklijst;
