import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';

function EersteKerstdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <div className="eerste-kerstdag-info">
        <h2>Wat is Eerste Kerstdag?</h2>
        <p>
          Eerste Kerstdag, gevierd op 25 december, is een van de belangrijkste feestdagen in Nederland. Het markeert de geboorte van Jezus Christus 
          en wordt uitgebreid gevierd met familie, vrienden, eten en verschillende tradities.
        </p>
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Voor christenen wereldwijd is Eerste Kerstdag een belangrijke religieuze feestdag. Het herdenkt de geboorte van Jezus Christus in Bethlehem. 
          Veel mensen gaan op deze dag naar de kerk voor een speciale kerstmis, waarin het kerstverhaal wordt verteld en er gezamenlijk wordt gebeden en gezongen.
        </p>
        <h3>Familie en Vrienden</h3>
        <p>
          Eerste Kerstdag wordt vaak gevierd met een uitgebreid familiefeest. Families komen samen om te genieten van een feestelijke maaltijd, 
          vaak bestaande uit traditionele gerechten zoals kalkoen, kerststol en andere lekkernijen. Het is een tijd voor samenzijn, gezelligheid en het uitwisselen van cadeaus.
        </p>
        <h3>Kerstversieringen</h3>
        <p>
          De weken voorafgaand aan Kerstmis zijn veel huizen en straten versierd met kerstlichtjes, kerstbomen en andere decoraties. 
          De kerstboom, vaak rijkelijk versierd met ballen, lichtjes en slingers, vormt het middelpunt van de kerstversiering in veel huishoudens.
        </p>
        <h3>Tradities en Activiteiten</h3>
        <p>
          Naast de kerkbezoeken en familiebijeenkomsten zijn er tal van andere tradities en activiteiten die met Eerste Kerstdag worden geassocieerd. 
          Dit omvat het luisteren naar kerstliederen, het kijken naar kerstfilms en het bezoeken van kerstmarkten. Veel steden en dorpen organiseren speciale kerstactiviteiten, 
          zoals schaatsbanen en kerstconcerten.
        </p>
        <h3>Liefdadigheid en Gemeenschap</h3>
        <p>
          Kerstmis is ook een tijd van geven en liefdadigheid. Veel mensen nemen de tijd om iets goeds te doen voor anderen, zoals het doneren aan goede doelen, 
          vrijwilligerswerk doen of kerstpakketten samenstellen voor de minderbedeelden. Het is een tijd om na te denken over de betekenis van het feest en om te delen met degenen die het nodig hebben.
        </p>
        <p>
          Al met al is Eerste Kerstdag een dag van vreugde, samenzijn en reflectie. Of je nu deelneemt aan religieuze vieringen, geniet van familieactiviteiten of anderen helpt, 
          Eerste Kerstdag biedt een gelegenheid om te vieren en te waarderen wat echt belangrijk is in het leven.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <HolidayTable holidays={filteredHolidays} />
     
      </div>
    </div>
  );
}

export default EersteKerstdag;
