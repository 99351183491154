import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';

function Kerstvakantie({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
        <h2>Wat is de Kerstvakantie?</h2>
        <p>
          De Kerstvakantie is een schoolvakantie in Nederland die plaatsvindt aan het einde van het kalenderjaar. 
          Deze vakantie omvat zowel Kerstmis als Nieuwjaarsdag en biedt leerlingen en leraren een pauze van ongeveer twee weken.
        </p>
      <div className="important-notes">
        <h2>Belangrijke Opmerkingen</h2>
        <ul>
         <li><strong>Kerstvakantie:</strong> Scholen zijn verplicht gesloten.</li>
        <li><strong>Voor alle vakanties geldt:</strong> Controleer altijd de specifieke data op de website van de school, aangezien scholen soms mogen afwijken van de landelijke adviesdata.</li>
        </ul>
      </div>
      <div className="kerstvakantie-info">
        <AdSenseAd />
        <h3>Schoolvakantie voor Basisonderwijs en Voortgezet Onderwijs</h3>
        <p>
          De Kerstvakantie geldt voor zowel het basisonderwijs als het voortgezet onderwijs. Het biedt een welkome onderbreking 
          tijdens de donkere wintermaanden en de mogelijkheid om tijd door te brengen met familie en vrienden.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          Kerstmis is een van de belangrijkste feestdagen in Nederland en wordt uitgebreid gevierd. Tijdens de Kerstvakantie worden er veel kerstmarkten, 
          concerten en andere evenementen georganiseerd. Huizen en straten zijn versierd met kerstlichtjes en kerstbomen, en er heerst een feestelijke sfeer.
        </p>
               <img src={`${process.env.PUBLIC_URL}/images/kerstvakantie-activities.jpg`} alt="herfstvakantie activities" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />
               <h3>Activiteiten tijdens de Kerstvakantie</h3>
        <p>
          Veel families gebruiken de Kerstvakantie om op vakantie te gaan, zowel in Nederland als naar bestemmingen in het buitenland. 
          Populaire activiteiten zijn schaatsen, skiën, wandelen in de natuur en het bezoeken van kerstmarkten en winterfestivals.
        </p>
        <h3>Oud en Nieuw</h3>
        <p>
          De Kerstvakantie omvat ook Oudjaarsdag en Nieuwjaarsdag, belangrijke momenten om het oude jaar af te sluiten en het nieuwe jaar te verwelkomen. 
          Veel mensen vieren deze dagen met familiefeesten, vuurwerk en het maken van goede voornemens voor het komende jaar.
        </p>
        <h3>Rust en Ontspanning</h3>
        <p>
          Naast de feestelijkheden is de Kerstvakantie ook een tijd voor rust en ontspanning. Het is een kans om even bij te komen van de drukte van het schooljaar, 
          te genieten van vrije tijd en quality time door te brengen met dierbaren.
          <img src={`${process.env.PUBLIC_URL}/images/kerstvakantie-nederland.jpg`} alt="herfstvakantie nederland" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        </p>
        <h3>Voorbereiding op het Nieuwe Jaar</h3>
        <p>
          De Kerstvakantie biedt ook een moment om vooruit te kijken naar het nieuwe jaar. Het is een tijd om plannen te maken, doelen te stellen en zich voor te bereiden op de uitdagingen en kansen die het nieuwe jaar zal brengen.
        </p>
        <p>
          Al met al biedt de Kerstvakantie een waardevolle onderbreking in het schooljaar, een gelegenheid om belangrijke feestdagen te vieren en een kans om te genieten van de winter met familie en vrienden. 
          Of je nu kiest voor actieve uitstapjes, culturele evenementen of gewoon rust en ontspanning, de Kerstvakantie biedt voor ieder wat wils.
        </p>
      </div>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
    </div>
  );
}

export default Kerstvakantie;
